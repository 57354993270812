<template lang="">
    <v-card>       
        <s-crud
            :config="config"
            title="Motivos de Amonestaciones"
            :filter="filter"
            add
            edit
            @save="save($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-date
                                label="Tipo de Amonestacion"
                                v-model="filter.TypeAdmonition"
                            ></s-date>
                        </v-col>                       
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >   
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition 
                                :def="1052" 
                                label="Tipo de Amonestacion" 
                                v-model="props.item.TypeAdmonition" 
                            />
                        </v-col>                
                        <v-col>
                            <s-text
                                label="Motivo de Amonestacion"                               
                                v-model="props.item.RflDescription"
                            ></s-text>
                        </v-col>
                    </v-row>                      
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>
</template>
<script>

    import _serviceReasonForLivelihood from "@/services/HumanResource/ReasonForLivelihood"

    export default {
        data(){
            return{
                config: {
                    service: _serviceReasonForLivelihood,
                    model: {
                        RflID : "ID"
                    },
                    headers: [
                        {text: "ID", value: "RflID"},
                        {text: "Descripcion", value: "RflDescription"},
                        {text: "Tipo de Amonestacion", value: "TypeAdmonition"},
                        {text: "Estado", value: "SecStatus"}
                    ]
                },
                filter:{
                    TypeAdmonition: ""
                },
            }
        },
        methods: {
            save(item){

                if(item.RflDescription.length < 1){
                    this.$fun.alert("El campo Descripcion es requerido", "warning");
                    return;
                }


                item.save();
            }
        }
    }

</script>