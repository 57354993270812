import Service from "../Service";

const resource = "/humanresource/reasonforlivelihood/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID }
        });
    },
    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },
    list(typeAdmonition, requestID) {
        return Service.post(resource + "pagination", typeAdmonition, {
            params: { requestID: requestID },
        });
    }
};